import React from 'react';
import './module-one.css';

function ModuleOne({urlSearchParams}) {
  const utm_source = urlSearchParams.get("utm_source");
  const utm_medium = urlSearchParams.get("utm_medium");
  const utm_campaign = urlSearchParams.get("utm_campaign");
  let source = "KM2GO_landing";
  let medium = "S2H";
  if (utm_source) {
    source = utm_source;
  }
  if (utm_medium) {
    medium = utm_medium;
  }
  let kmUrl = `https://www.kodakmoments.com/?utm_source=${source}&utm_medium=${medium}`;
  if (utm_campaign) {
    kmUrl = kmUrl + `&utm_campaign=${utm_campaign}`;
  }
    return (
      <section className="module-one">
        <div className="img-wrapper">
          <a href="/">
              <img
                  srcSet="/images/KMTG_LandingPage-Mod-1_@2x.jpg 2x, /images/KMTG_LandingPage-Mod-1.jpg 1x"
                  src="/images/KMTG_LandingPage-Mod-1.jpg"
                  alt="LandingPage-Mod-1" />
          </a>
        </div>
        <div className="content">
            <p className="module-one-body">Turn your favorite photos into personalized gifts and decor. Your moments are made to last with the highest quality materials, from a brand that's been trusted for generations.</p>
          <a href={kmUrl} className="module-one-cta">Shop Now</a>
        </div>
      </section>
    )
}

export default ModuleOne;
