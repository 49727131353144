import React from 'react';
import './social-links.css';

function SocialLinks() {


  return (
    <div className="social-links">
      <ul className="social-links-list">
        <a href="https://www.facebook.com/KodakMomentsUS">
            <li>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 248.67">
                  <title>facebook</title>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path d="M238,0H12A12,12,0,0,0,0,12V236.67a12,12,0,0,0,12,12H125.78V155H102.51V113.41h23.27V86.49c0-19.27,9.15-49.36,49.37-49.36l36.22.14V77.63h-26.3c-4.3,0-10.37,2.14-10.37,11.31v24.48H212L207.69,155h-33v93.7H238a12,12,0,0,0,12-12V12A12,12,0,0,0,238,0Z" style={{fill: "rgb(255, 255, 255)"}}/>
                    </g>
                  </g>
                </svg>
              </span>
            </li>
        </a>


        <a href="https://www.instagram.com/kodakmoments/">
        <li>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.84 503.84">
          <title>instagram</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Logo">
              <g>
                <path d="M251.92,45.39c67.27,0,75.23.26,101.8,1.47,24.56,1.12,37.9,5.22,46.78,8.67a78.05,78.05,0,0,1,29,18.84,78.05,78.05,0,0,1,18.84,29c3.45,8.88,7.55,22.22,8.67,46.78,1.21,26.56,1.47,34.53,1.47,101.8s-.26,75.23-1.47,101.8c-1.12,24.56-5.22,37.9-8.67,46.78a83.43,83.43,0,0,1-47.81,47.81c-8.88,3.45-22.22,7.55-46.78,8.67-26.56,1.21-34.53,1.47-101.8,1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67a78.05,78.05,0,0,1-29-18.84,78.05,78.05,0,0,1-18.84-29c-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.56-1.47-34.53-1.47-101.8s.26-75.23,1.47-101.8c1.12-24.56,5.22-37.9,8.67-46.78a78.05,78.05,0,0,1,18.84-29,78.05,78.05,0,0,1,29-18.84c8.88-3.45,22.22-7.55,46.78-8.67,26.56-1.21,34.53-1.47,101.8-1.47m0-45.39c-68.42,0-77,.29-103.87,1.52S102.93,7,86.9,13.23A123.49,123.49,0,0,0,42.28,42.28,123.49,123.49,0,0,0,13.23,86.9C7,102.93,2.74,121.24,1.52,148.05S0,183.5,0,251.92s.29,77,1.52,103.87S7,400.91,13.23,416.94a123.49,123.49,0,0,0,29.06,44.62A123.49,123.49,0,0,0,86.9,490.62c16,6.23,34.34,10.49,61.15,11.71s35.45,1.52,103.87,1.52,77-.29,103.87-1.52,45.13-5.48,61.15-11.71a128.82,128.82,0,0,0,73.68-73.68c6.23-16,10.49-34.34,11.71-61.15s1.52-35.45,1.52-103.87-.29-77-1.52-103.87-5.48-45.13-11.71-61.15a123.49,123.49,0,0,0-29.06-44.62,123.49,123.49,0,0,0-44.62-29.06C400.91,7,382.6,2.74,355.79,1.52S320.34,0,251.92,0Z" style={{fill: "rgb(255, 255, 255)"}}/>
                <path d="M251.92,122.56A129.36,129.36,0,1,0,381.29,251.92,129.36,129.36,0,0,0,251.92,122.56Zm0,213.34a84,84,0,1,1,84-84A84,84,0,0,1,251.92,335.89Z" style={{fill: "rgb(255, 255, 255)"}} />
                <circle cx="386.4" cy="117.44" r="30.23" style={{fill: "rgb(255, 255, 255)"}}/>
              </g>
            </g>
          </g>
        </svg>
        </li>
        </a>

        <a href="https://www.youtube.com/kodakmomentsUS">
          <li>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 1022.8 718.8">
              <title>youtube</title>
              <path d="M1022.8,298v122c-0.4,1.7-0.7,3.5-0.9,5.3c-0.7,16.3-1,32.5-2.1,48.8c-1.4,21.5-3.4,43-4.9,64.5
                c-1.3,21.7-5.1,43.2-11.3,64.1c-8.4,27.8-19.3,54.1-43,73.2c-15,12.1-31.3,21.1-49.9,26.3c-19.2,5.4-38.9,6.3-58.6,7.9
                c-17.5,1.4-35.1,2.2-52.7,3c-15.9,0.8-31.9,1.5-47.8,2c-20.9,0.7-41.9,1.4-62.8,2c-14.8,0.4-29.6,0.8-44.4,1
                c-18.8,0.4-37.6,1-56.4,0.9c-46.5-0.1-92.9-0.5-139.4-0.9c-24.3-0.2-48.6-0.6-72.9-1c-11.8-0.2-23.6-0.6-35.4-0.9
                c-25.3-0.7-50.5-1.3-75.8-2.1c-16.3-0.5-32.6-0.9-48.8-2c-24.6-1.7-49.3-3.6-73.9-6c-22.5-2.3-44.2-7.6-63.8-19.3
                c-14.4-8.6-27.2-18.9-36.7-33c-13.6-20.3-21.4-42.9-26.3-66.4c-4-19.3-6.1-39-8.2-58.5c-2.2-19.9-3.7-40-4.9-60
                c-1.1-17.6-2-35.2-2-52.7c0-40.5,0.1-81,1-121.4c0.5-26.7,2.3-53.4,3.8-80.1c1-16.6,2.3-33.1,4.1-49.6c2.9-24.8,8.9-49.1,18-72.3
                c11.6-30.3,32.1-52.2,61-66.9c20.5-10.4,42.5-13.8,64.9-15.7C174.2,8.3,195.6,7.2,217,6c15.4-0.9,30.9-1.5,46.3-2
                c21.1-0.8,42.2-1.4,63.3-2c13.8-0.4,27.6-0.7,41.4-1c23.3-0.4,46.6-1,69.9-1c62.8,0.1,125.6,0.5,188.4,1c12,0.1,23.9,0.6,35.9,0.9
                c26.1,0.7,52.2,1.3,78.3,2.1c16.4,0.5,32.9,1.2,49.3,2c19.7,0.9,39.5,1.6,59.2,3c16.9,1.2,33.8,2.4,50.4,5.1
                c26.6,4.4,49.4,16.9,68.8,35.6c14,13.5,22.7,30.2,29.3,48.1c8.1,21.6,13.5,44.1,16.1,67.1c1.4,12.7,2.3,25.4,3.3,38.2
                c1.3,17.4,2.8,34.7,3.8,52.1c0.7,12.9,0.7,25.9,1.1,38.9C1022.1,295.4,1022.4,296.7,1022.8,298z M407.3,489.7c2.3-0.9,4-1.5,5.5-2.3
                l78.8-40.8c28.8-14.9,57.6-29.8,86.3-44.7l65.1-33.6c1-0.6,2-1.3,3-2.1L407.3,206.9V489.7z"
                style={{fill: "rgb(255, 255, 255)"}}
                />
            </svg>
          </li>
        </a>

        <a href="https://twitter.com/KodakMomentsUS">
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.09 272.93">
              <defs>
                <clipPath id="clip-path" transform="translate(-0.01)">
                  <rect y="0.06" width="328.8" height="273.84" style={{fill: "none"}}/>
                </clipPath>
              </defs>
              <title>twitter</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <g id="Layer_1-2-2" data-name="Layer 1-2">
                    <g style={{"clip-path": "url(#clip-path)"}}>
                      <path d="M322.91,31.75a129.7,129.7,0,0,1-22.63,7.63A70.5,70.5,0,0,0,318.9,9.73a3.7,3.7,0,0,0-5.41-4.31A130.51,130.51,0,0,1,278.9,19.71a8.76,8.76,0,0,1-2.16.27,9.17,9.17,0,0,1-6.06-2.31A70.73,70.73,0,0,0,223.88,0a75.58,75.58,0,0,0-22.41,3.46,68.54,68.54,0,0,0-46.19,49.38,75.5,75.5,0,0,0-1.67,26,2.54,2.54,0,0,1-.64,2,2.63,2.63,0,0,1-2,.89h-.25A185.5,185.5,0,0,1,23.27,13.63a3.7,3.7,0,0,0-6.06.47,70.83,70.83,0,0,0,11.51,86,63.35,63.35,0,0,1-16.14-6.25A3.7,3.7,0,0,0,7.09,97a70.83,70.83,0,0,0,41.32,65.25H46.89A63.33,63.33,0,0,1,35,161.17a3.7,3.7,0,0,0-4.22,4.76,70.9,70.9,0,0,0,56,48.22,130.55,130.55,0,0,1-73.11,22.2H5.47a5.42,5.42,0,0,0-5.3,4.09,5.6,5.6,0,0,0,2.68,6.19,194.15,194.15,0,0,0,97.56,26.3c30,0,58-6,83.38-17.69a184.09,184.09,0,0,0,61-45.71A200.39,200.39,0,0,0,282.15,147a197.65,197.65,0,0,0,12.7-68.47v-1.1a12,12,0,0,1,4.5-9.37,139.87,139.87,0,0,0,28.13-30.87,3.7,3.7,0,0,0-4.57-5.44Z" transform="translate(-0.01)" style={{fill: "rgb(255, 255, 255)"}}/>
                    </g>
                  </g>
                </g>
              </g>
            </svg>

            </li>
        </a>


        <a href="https://www.pinterest.com/kodakmomentpins/">
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.95 329.95">
              <defs>
                <clipPath id="clip-path" transform="translate(0 -0.05)">
                  <rect width="330" height="330.96" style={{"fill": "none"}}/>
                </clipPath>
              </defs>
              <title>pinterest</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <g style={{"clip-path": "url(#clip-path)"}}>
                    <path d="M329.88,169.86A165,165,0,0,0,162.52.07C72.19,1.38-.69,76,0,166.32A165,165,0,0,0,92.92,313.47a4.66,4.66,0,0,0,6.69-4.37c-.4-10.61-.05-22,2.07-31.08S112,234.3,117.36,211.49a81.56,81.56,0,0,0,.83-33.86,69.14,69.14,0,0,1-1.2-12.81c0-24.38,14.13-42.59,31.73-42.59,15,0,22.19,11.23,22.19,24.7,0,15-9.58,37.54-14.52,58.39-4.13,17.45,8.75,31.69,26,31.69,31.17,0,55.13-32.86,55.13-80.3,0-42-30.17-71.34-73.25-71.34-49.89,0-79.18,37.42-79.18,76.1,0,15.07,5.81,31.23,13.05,40a5.25,5.25,0,0,1,1.22,5C98,212,95,224,94.45,226.4c-.76,3.21-2.54,3.89-5.86,2.35C66.68,218.55,53,186.53,53,160.81,53,105.49,93.18,54.69,168.86,54.69,229.69,54.69,277,98,277,156,277,216.4,238.86,265,186,265c-14,0-27.3-5.71-35.13-13.46a4.38,4.38,0,0,0-7.34,2c-2.76,10.51-7.21,27.5-8.65,33-2.59,9.95-8,21.52-13.5,31.61a4.67,4.67,0,0,0,3,6.74A165.48,165.48,0,0,0,166.54,330c86.57-.8,160.87-73.6,163.35-160.13Zm0,0" transform="translate(0 -0.05)" style={{fill: "rgb(255, 255, 255)"}}/>
                  </g>
                </g>
              </g>
            </svg>
            </li>
        </a>
      </ul>
    </div>
  )
}

export default SocialLinks;
