import React from 'react';
import Util from '../utils/utility.js';
import LASGeolocation from "./LASGeolocation.jsx";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

const urls = require("../utils/urls.json");

let azureFuncBase = urls.production.vgr_func;

const host = window.location.hostname;
if (host.includes("-stage")) {
    azureFuncBase = urls.staging.vgr_func;
} else if (host.includes("-dev") || host === "localhost") {
    azureFuncBase = urls.development.vgr_func;
}

class LASSearch extends React.Component {
    constructor(props) {
        super(props);

        this.findVoyagers = this.findVoyagers.bind(this);
        this.setSearching = this.props.setSearching;
        this.isSearching = this.props.isSearching;

        this.searchText = null;
        this.miles = false;
    }

    componentDidMount() {
        this.grabRoughGeoData();
    }

    render() {
        // Get the messages locale obj from props (contains all of the translated messages)
        const msgObj = this.props.messages;
        // Find the placeholder for the search bar, or default to english
        const localSearchPlaceholder = (msgObj && msgObj["locator-search-field"]) || "Search City or Zip";

        // Loading spinner is only created while searching
        let spinner = null;
        if (this.isSearching()) {
            spinner = (
                <div className="busy-small"/>
            );
        }

        return (
            <React.Fragment>
                <div className="combo-searchbar">
                    <input  id="searchtext"
                            className="combo-input"
                            type="search"
                            name="location"
                            placeholder={localSearchPlaceholder}
                            ref = { input => this.searchText = input }
                            onKeyUp = { (event) => {
                                const keyCode = event.keyCode;
                                if (keyCode === 13 && this.searchText && this.searchText.value) {
                                    event.preventDefault();
                                    this.search();
                                    this.searchText.blur();
                                }
                    } }/>
                    <button className="icon-button"
                            id="findlocationbutton"
                            type="button"
                            onClick={() => { this.search() }}>
                        <img src="/images/icon_search.svg" alt="search icon" />
                    </button>
                </div>
                <LASGeolocation findVoyagers = { this.findVoyagers }
                                setSearching = { this.setSearching }
                                setError = { this.props.setError }
                                updateMachineList = { this.props.updateMachineList }
                                isSearching = { this.isSearching } />
                {spinner}
            </React.Fragment>
        );
    }

    /**
     * Performs a search and updates the machine entry list accordingly.
     */
    search() {
        if (this.isSearching()) {
            console.log('User is already searching, so we will not search again.');
            return;
        }

        this.props.updateMachineList();
        this.props.setSearching(true);
        const searchParams = this.searchText.value;
        Util.findAddressLocation(searchParams,
            (latitude, longitude) => {
                this.findVoyagers(latitude, longitude);
            },
            (err) => {
                console.log('did not find anything');
                // Address is unknown
                
                // Clear out the current list
                this.props.updateMachineList();

                // We are not searching anymore, so set that to false
                this.props.setSearching(false);

                // Set the error
                this.props.appInsights.trackTrace({message: err, severityLevel: SeverityLevel.Error})
                this.props.setError('locator-status-unknown');
                this.props.showModal(true);
        }, this.geoLocationData);
    }

    /**
     * Grabs JSON that displays the rough location of the user (used for determining the country).
     */
    grabRoughGeoData() {
        
        fetch(`${azureFuncBase}/api/geo-ip-lookup`).then((response) => {
          return response.json();
        }).then((json) => {
          this.geoLocationData = json;
          this.props.setCountryState(json.country);
        });
      }

    /**
     * Finds voyagers within a radius, given a latitude and longitude.
     * 
     * @param lat           The latitude of the computer/device.
     * @param long          The longitude of the computer/device.
     * @param clearSearch   Flag that determines if we should clear the search input value
     */
    findVoyagers(lat, long, clearSearch = false) {
        // Clear out the currently-displayed machine list and set 'searching' to true
        this.props.updateMachineList([]);
        this.props.setSearching(true);

        // Used when clicking the geolocation button
        if (clearSearch) {
            this.clearSearchField();
        }

        const demoMode = (this.props.mode === 'demo') || this.props.debug;
        Util.findVoyagersNearLocation(lat, long, this.props.searchRadius, demoMode, this.props.useDev, (resp) => {
            this.props.setSearching(false);
            if (resp.length === 0) {
                // If we did not find any machines, we want to display an error message to the user
                this.props.setError('locator-status-unknown');
                this.props.showModal(true);
            } else {
                // If we found machines, send those to update the page
                this.props.updateMachineList(resp);
            }
        },
        (err) => {
            // from my understanding, this is a server error, NOT a 0 found error
            this.props.setSearching(false);
            console.error("server error getting results:", err);
            this.props.appInsights.trackTrace({message: err, severityLevel: SeverityLevel.Error})
            this.props.setError('locator-server-error');
            this.props.showModal(true);
        });
    }

    /**
     * Clears the search field.
     * 
     * Clears the search field and replaces the current text (if any) with an empty string.
     * This function is passed to LASGeolocation, so the search text can be cleared when
     * the geolocator button is pressed.
     */
    clearSearchField() {
        this.searchText.value = "";
    }
}

export default LASSearch;