import React from 'react';
import Header from "../landing/LASHeader.jsx";
import HolidayLandingPage from './HolidayLandingPage/HolidayLandingPage.jsx';

const OnboardingWrapper = (props) => {
    const hasPromo = props.hasPromo;
    let heroImgSrc = "/images/KMTG_Mobile_LandingPage-Hero-1_@2x.jpg";
    let classes = ["landing-wrapper"];

    if (hasPromo === true) {
        heroImgSrc = "/images/KMTG_Mobile_LandingPage-Hero-1_@2x.jpg";
        classes.push("promo");
    }

    return (
        <div className={classes.join(" ")}>
            <Header />
            <HolidayLandingPage
              hasPromo={hasPromo}
              heroImgSrc={heroImgSrc}/>
        </div>
    )
}

export default OnboardingWrapper;
