import React, { useState } from 'react';
import './LASLanding.css';
import Footer from "./LASFooter.jsx";
import Header from "./LASHeader.jsx";
import Body from "./LASBody.jsx";
import IntlProviderProps from '../locale/locale.js';
import ErrorBoundary from './ErrorBoundary.jsx';

const IntlProvider = require("react-intl").IntlProvider;

/**
 * Small wrapper component to provide the intl inject
 */
const LASLandingWrapper = (props) => {
    const tempIntlProps = IntlProviderProps.IntlProviderProps();
    let intlProps = Object.assign({}, tempIntlProps, props);
    const [loading, setLoading] = useState(false);

    if (loading) {
        return (
            <ErrorBoundary>
                <IntlProvider {...intlProps}>
                    <div className="combo-content">
                        <div className="full-screen-loading"></div>
                    </div>
                </IntlProvider>
            </ErrorBoundary>
        );
    } else {
        return (
            <ErrorBoundary>
                <IntlProvider {...intlProps}>
                    <div className="combo-content">
                        <Header siteNameKey={props.siteNameKey} toGoOverride={props.filterType} />
                        <Body {...intlProps} setLoading = { () => {
                            setLoading(true);
                        } } />
                        <Footer className="combo-footer" />
                    </div>
                </IntlProvider>
            </ErrorBoundary>
        );
    }
}


export default LASLandingWrapper;
