import React from 'react';
import Util from '../utils/utility.js';
import FormattedMessage from '../utils/FormattedMessage'

const urls = require("../utils/urls.json");

class LASMachine extends React.Component {
    render() {
        const m = this.props.machine;

        const extra = this.buildDemoInfo(this.props.mode === 'demo' || this.props.debug);

        // miles or km
        const distance = this.formatDistance(m.distance, this.props.miles ? 'miles' : 'km');
        const distanceMarker = this.props.miles ?
            (
                <FormattedMessage id="distance-miles" values={{
                    distance: distance
                }} />
            )
            :
            (
                <FormattedMessage id="distance-km" values={{
                    distance: distance
                }} />
            );

        let storeName = this.createStoreName(m);
        let addressStr = this.createAddress(m);

        return (
            <div className="machine-entry" onClick={() => {
                this.handleMachineClick();
            }}>
                <div className="machine-details">
                    <p className="machine-store-name">{storeName}</p>
                    <div className="store-address">
                        <span>
                            {addressStr}&nbsp;-&nbsp;
                            <span>
                                {distanceMarker}
                            </span>
                        </span>
                        {extra}
                    </div>
                </div>
                <div className="machine-entry-chev">
                    <div className="chevron-right"></div>
                </div>
            </div>
        );
    }

    createStoreName(machine) {
        let storeName = machine.storename;
        if (!storeName && machine.storeid && isNaN(machine.storeid)) {
            storeName = machine.storeid;
        }

        if (!storeName && machine.retailerid) {
            storeName = machine.retailerid;
            if (machine.storeid) {
                storeName += ' - ' + machine.storeid;
            }
        }

        if (!storeName && machine.storeid) {
            storeName = machine.storeid;
        }

        if (!storeName && machine.knumber) {
            storeName = machine.knumber;
        }

        if (!storeName) {
            storeName = "???";
        }

        return storeName;
    }

    createAddress(machine) {
        let address = machine.addr1;
        if ((!address || (address && address === '')) && machine.addr2) {
            address = machine.addr2;
        }

        if (!address && machine.addressconfigured) {
            address = machine.addressconfigured.split("\\n")[0];
        }

        if (!address && machine.addressinferred) {
            address = machine.addressinferred.split(",")[0];
        }

        if (!address || (address && address.length === 0)) {
            console.log('Machine doesnt have a correct address!');
            console.log(machine);
        }

        return address;
    }

    /**
     * Builds out extra information to be shown if we are showing debug information.
     *
     * @param   extra   True or False, depending on if we want to display extra information.
     */
    buildDemoInfo(extra) {
        if (!extra) {
            return null;
        }
        return (<div>
            <p><span>Retailer/store: {this.props.machine.retailerid} / {this.props.machine.storeid}</span></p>
            <p><span>K-number: {this.props.machine.knumber}</span></p>
            <p><span>{this.props.machine.serverid}</span></p>
        </div>
        );
    }

    /**
     * Handles the functionality of clicking a machine entry.
     *
     * This method was taken from the old page. Pretty much just builds out a URL
     * and a bunch of parameters based on the listing.
     */
    async handleMachineClick() {
        if (Util.getQueryStringValue("web-demo-mode") === "true") {
            return alert("Navigation canceled in demo mode");
        }

        let selection = window.getSelection();
        if (selection && selection.toString()) {
            return;
        }

        this.props.setLoading();
        let m = this.props.machine;

        // Check if we can access the local version before navigating to the tracker itself
        // 'dnsname' appears to only show itself on non-athena entries
        if (m.dnsname) {
            m.localurl = '//' + m.dnsname + ':51000/WebKiosk2/version.json?t=' + new Date().getTime();
        }

        let cumulusRetailerId = 'PrintPlace';
        let cumulusStoreId = 'PrintPlace';
        if (m.knumber === 'KDEMO' || m.knumber === '00000000') {
            cumulusRetailerId = 'marty-USA';
            cumulusStoreId = 'SysInt03';
        }

        const hub = encodeURIComponent(m.hub);
        let exists = false;

        //
        // disable hybrid connect until it can be fixed (see VGR-4886)
        //
        // exists = await this.localAddressConnect(m);

        // see if there's an override for the SPE base URL configured for this
        // mobile-in-store version (unless configured off by query param).
        let misbaseurl = "";
        if (!(Util.getQueryStringValue("no-cloud-spe") === "true")) {
            misbaseurl = await this.getBaseUrlForMisVersion(m.misversion);
        }

        if (exists) {
            if (m.serverid) {
                let ti = m.trackerinstance && m.trackerinstance.length > 0 ? `${m.trackerinstance}/` : "";

                // if there's no SPE base URL configured, get SPE from the system via Tracker
                let baseurl = misbaseurl;
                if (!baseurl) {
                    baseurl = `${m.hub}${ti}webkiosk2/${m.serverid}`;
                }

                let url = `${baseurl}/?relay=true` +
                `&las=true` +   // lets SPE know we came from LAS page
                `&countryCode=${this.props.country}` +
                `&c-server=${hub}${m.serverid}` +
                `&v-server=${hub}` +
                `&v-port=${m.hub.startsWith("https") ? "443" : "80"}` +
                `&v-prefix=${ti}${m.serverid}` +
                `&c-retailer-id=${cumulusRetailerId}` +
                `&c-store-id=${cumulusStoreId}`;

                const cumulusLocalConnection = "//" + m.dnsname + ":51000";

                let urlhybrid = `${baseurl}/?relay=true` +
                    `&hybridConnect=true` +
                    `&c-server=${cumulusLocalConnection}&countryCode=${this.props.country}&v-server=${hub}&v-port=${m.hub.startsWith("https") ? "443" : "80"}` +
                    `&v-prefix=${ti}${m.serverid}&c-retailer-id=${cumulusRetailerId}&c-store-id=${cumulusStoreId}`;

                // If there is some sort of auth passed, use it
                if (m.knumber === "KDEMO" || m.knumber === "00000000") {
                    urlhybrid = urlhybrid + "&c-user=MOBPRINT_ANDROID_Kodak&c-pass=KdkAndr01dApp";
                    url = url + "&c-user=MOBPRINT_ANDROID_Kodak&c-pass=KdkAndr01dApp";
                    console.log("Setting special params for k number 00000000");
                }

                console.log(`url is ${url} using the standard connection`);
                console.log(`url is ${urlhybrid} using the hybrid connection`);

                window.location.assign(Util.addExtraQueryParams(urlhybrid));
            } else {
                // TODO: error no serverid
            }
        } else {
            if (m.systemType && m.systemType === "athena") {
                // launch the cloud-based SPE
                // remove @ from server id, pass to app as "claim-server-id"
                let serverid = m.serverid.replace("@", "");
                let url = `${m.hub}&claim-server-id=${serverid}`;
                window.location.assign(Util.addExtraQueryParams(url));
            } else if (m.serverid) {

                let ti = m.trackerinstance && m.trackerinstance.length > 0 ? `${m.trackerinstance}/` : "";

                // if there's no SPE base URL congifured, get SPE from the system via Tracker
                let baseurl = misbaseurl;
                if (!baseurl) {
                    baseurl = `${m.hub}${ti}webkiosk2/${m.serverid}`;
                }

                let url = `${baseurl}/?relay=true&las=true&countryCode=${this.props.country}&hybridConnect=false&c-server=${hub}${ti}${m.serverid}&v-server=${hub}&v-port=${m.hub.startsWith("https") ? "443" : "80"}&v-prefix=${ti}${m.serverid}&c-retailer-id=${cumulusRetailerId}&c-store-id=${cumulusStoreId}`;

                // If there is some sort of auth passed, use it
                if (m.knumber === "KDEMO" || m.knumber === "00000000") {
                    url += "&c-user=MOBPRINT_ANDROID_Kodak&c-pass=KdkAndr01dApp";
                }

                console.log(`url is ${url}`);
                window.location.assign(Util.addExtraQueryParams(url));
            } else {
                alert("This location has no serverid");
            }
        }
    }

    /**
     * Connects to a URL.
     *
     * Taken from the old page.
     *
     * @param   location    A location to connect to.
     */
    localAddressConnect(location) {
        if (!location.localurl) {
            console.log("No localurl, return false instantly.");
            return Promise.resolve(false);
        } else {
            console.log("Found localurl, attempt to reach local kiosk" + location.localurl);
            return new Promise((resolve, reject) => {
                var http = new XMLHttpRequest();

                http.timeout = 2000;

                http.open('GET', location.localurl);

                http.onreadystatechange = function () {
                    if (this.readyState === this.DONE) {
                        if (this.status === 0) {
                            console.warn("Status is zero, this will probably end up in one of the error functions below");
                            return;
                        }

                        if (this.status === 200) {
                            console.log("Got a readystatechange, done, and valid response");
                            resolve(true);
                        } else {
                            console.log("Got a readystatechange, done, but invalid response");
                            resolve(false);
                        }
                    } else {
                        console.log("Got a readystatechange but it was not DONE");
                    }
                };

                http.ontimeout = function (err) {
                    console.log("Connection timed out, throw back to the internet connection");
                    resolve(false);
                };

                http.onerror = function (err) {
                    console.error("Had an error while trying to access kiosk. throw back to internet connection");
                    resolve(false);
                }

                http.send();
            });
        }
    }

    // return a base url for the given mis (mobile in store) version
    async getBaseUrlForMisVersion(misversion) {

        // determine which function app to talk to (prod, dev, staging)
        let vgrFuncBase = urls.production.vgr_func;
        const host = window.location.hostname;
        if (host.includes("-stage")) {
            vgrFuncBase = urls.staging.vgr_func;
        } else if (host.includes("-dev") || host === "localhost") {
            vgrFuncBase = urls.development.vgr_func;
        }

        // create the URL and fetch the result, which may be an empty string is there's
        // no configured base url
        const url = `${vgrFuncBase}/api/get-base-url-for-misversion?misversion=${misversion}`;
        let result = "";
        let response;
        try {
            response = await fetch(url, { cache: "no-cache", headers: {"Content-Type": "application/json" }});
            if (response.ok) {
                let json = await response.json();
                result = json.baseurl || "";
            } else {
                console.error(`Non-success status from ${url}: ${response.status}`);
                result = "";
            }
        } catch(error) {
            console.error(`Had an error while trying to parse response (${response}) from ${url}`);
            result = "";
        }
        console.log(`getBaseUrlForMisVersion(${misversion}): returning (${result})`);
        return result;
    }

    /**
     * Convert a meters distance to a km or miles distance.
     *
     * Takes in the meters distance given by Util.findVoyagersNearLocation (in the
     * response return) and converts the value into either a miles representation
     * or a kilometers representation, depending on the users country.
     *
     * @param dist      The distance given by findVoyagersNearLocation (meters).
     * @param format    The format that the @param{dist} should be formatted to.
     *
     * @return          The correctly formatted distance
     */
    formatDistance(dist, format) {
        switch (format) {
            // Set the default to km
            default:
            case 'km':
                return (dist / 1000).toFixed(1);
            case 'miles':
                return (dist / 1609.344).toFixed(1);
        }
    }
}

export default LASMachine;
