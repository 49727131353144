import React from 'react';
import gpsLogo from '../images/icon_location_on.svg'

import FormattedMessage from '../utils/FormattedMessage'

class LASGeolocation extends React.Component {
    render() {
        if (!navigator.geolocation) {
            this.props.setError('locator-no-gps');
            this.props.setSearching(false);
            return null;
        }

        return (
            <div className="use-currentloc-on">
                <button className="loc-icon-button"
                        id="usegpsbutton"
                        type="button"
                        onClick={ () => {this.gpsButtonClick()} }>
                    <img src={gpsLogo} alt="location icon"/>
                </button>
                <button className="loc-link-button"
                    id="usegpsbuttonlink"
                    type="button"
                    onClick={ () => {this.gpsButtonClick()} }>
                        <FormattedMessage id="locator-use-current"/>
                </button>
            </div>
        );
    }

    /**
     * Fires off when either the icon or link is clicked.
     */
    gpsButtonClick() {
        if (this.props.isSearching()) {
            console.log('User is already searching, so we will not search again.');
            return;
        }
        this.props.updateMachineList();
        this.props.setSearching(true);
        navigator.geolocation.getCurrentPosition((pos) => {
                this.props.findVoyagers(pos.coords.latitude, pos.coords.longitude, true);
        }, () => {
            this.props.setError('locator-no-gps');
            this.props.setSearching(false);
        });
    }
}

export default LASGeolocation;