import * as FormattedMessage from "react-intl";
import * as PropTypes from "prop-types";
import * as React from "react";
import IntlProviderProps from '../locale/locale.js';
import Util from './utility.js';

const IntlFormattedMessage = FormattedMessage.FormattedMessage;

/**
 * This class is the development version of the formattedtext component
 * This component will handle inline editing of text areas
 */
class KAFormattedMessageDev extends React.Component {
    constructor(props) {
        super(props);
        this.state = { overrideText: "" };
    }

    getEnglishString(id) {
        let allprops = IntlProviderProps.IntlProviderProps();
        return allprops.allMessages["en"][id];
    }

    getDefaultString(id) {
        let allprops = IntlProviderProps.IntlProviderProps();
        return allprops.allMessages[allprops.locale][id];
    }

    handleEnterEditMode(event) {
        event.preventDefault();
        if (event.button === 2) {
            const defaultLocaleText = this.getDefaultString(this.props.id)

            let modifiedString = defaultLocaleText;
            const results = checkForOverrideText(this.props.id, this.props.currentLocale);
            if (results.status) {
                modifiedString = results.value;
            }

            let additionalText = "";
            let allprops = IntlProviderProps.IntlProviderProps();
            if (allprops.allMessages && allprops.locale !== "en") {
                additionalText = "Original 'en' Text: " + allprops.allMessages["en"][this.props.id];
            }

            let newText = prompt("Enter New Text for ID '" + this.props.id + "': \nOriginal '" + allprops.locale + "' Text: " + defaultLocaleText + "\n" + additionalText, modifiedString);

            if (newText === null || newText === "") {
                this.setState({ overrideText: "" });
            } else {
                // The text has been set to the default again, dont overrdie it
                if (newText === defaultLocaleText) {
                    removeTextOverride(this.props.id, this.props.currentLocale)
                    this.setState({ overrideText: null });
                } else {
                    // The text has been set to a new string, save the result and set the override
                    updateTextString(this.props.id, newText, this.props.currentLocale);
                    this.setState({ overrideText: newText });
                }
            }
        }
    }

    render() {
        const props = this.props;

        // This is the standard production way. No special code.
        if (Util.getQueryStringValue("web-demo-mode") !== "true") {
            return (
                <IntlFormattedMessage {...props} />
            )
        }

        // If we are in the special translation mode, use the modified version of the component
        return (
            <IntlFormattedMessage {...props} >
                {(intltext) => {
                    let displayString = intltext;

                    let classes = ["formatted-message-dev"];
                    let extraText = "";

                    const results = checkForOverrideText(props.id, props.currentLocale);
                    if (results.status) {
                        displayString = results.value;
                        classes.push("formatted-message-edit");
                    }


                    if (props.default_messages) {
                        extraText += "\nOriginal English: " + props.default_messages[props.id];
                    }

                    const titleString = "String Id: '" + props.id + "'" + extraText + "\n\nRight-click on this string to modify";

                    return (
                        <span className={classes.join(" ")} title={titleString} onMouseDown={(event) => { this.handleEnterEditMode(event); }} data-text-key={props.id} >
                            {displayString}
                        </span>
                    );

                }}
            </IntlFormattedMessage>
        );
    }
}

KAFormattedMessageDev.propTypes = {
    currentLocale: PropTypes.string,
    id: PropTypes.string,
    trunc: PropTypes.number,
    limitMessageLength: PropTypes.func
};

function checkForOverrideText(id, currentLocale) {
    let localeOverride = JSON.parse(localStorage.getItem("locale-override"));
    if (localeOverride && localeOverride[id + "." + currentLocale]) {
        return { status: true, value: localeOverride[id + "." + currentLocale] };
    } else {
        return { status: false };
    }
}

function updateTextString(id, newtext, locale) {

    let localeOverride = JSON.parse(localStorage.getItem("locale-override"));
    if (!localeOverride) {
        localeOverride = {};
    }

    localeOverride[id + "." + locale] = newtext;
    localStorage.setItem("locale-override", JSON.stringify(localeOverride));
}

function removeTextOverride(id, locale) {

    let localeOverride = JSON.parse(localStorage.getItem("locale-override"));
    if (!localeOverride) {
        localeOverride = {};
    }

    delete localeOverride[id + "." + locale];
    localStorage.setItem("locale-override", JSON.stringify(localeOverride));
}

export default KAFormattedMessageDev;

