import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }

    
    static getDerivedStateFromError(error) {
        return {
            error: true
        };
    }

    componentDidCatch(error, info) {
        console.log('Caught an error, info below.');
        console.log(info);
    }

    render() {
        if (!this.state.error) {
            return this.props.children;
        }

        return (
            <div>
                <button onClick={() => {
                    this.reloadApp();
                }}>
                    <span>Try Again</span>
                </button>
            </div>
        );
    }

    /**
     * Tries to reload the window.
     */
    reloadApp() {
        console.log('Attempting to reload the webpage.');
        window.location.reload();
    }
}

export default ErrorBoundary;