import React from 'react';
import SocialLinks from '../SocialLinks/SocialLinks.jsx';
import Footer from '../../../landing/LASFooter.jsx'
import IntlProviderProps from '../../../locale/locale.js';
import ErrorBoundary from '../../../landing/ErrorBoundary.jsx';

import './holiday-footer.css';
const IntlProvider = require("react-intl").IntlProvider;

function HolidayFooter(props) {
  const tempIntlProps = IntlProviderProps.IntlProviderProps();
  let intlProps = Object.assign({}, tempIntlProps, props);
  return (
    <>
    <div className="kmtg-holiday-footer">
      <span className="kmtg-holiday-footer-title"></span>
      <ul>
        <a href="http://apps.kodakmoments.com/kodak-moment-of-the-day/"><li>Kodak Moment of the Day</li></a>
        <a href="http://apps.kodakmoments.com/projects-center/"><li>Inspiration</li></a>
        <a href="https://www.kodakmoments.com/about/"><li>About us</li></a>
        <a href="http://kodakmoments.kodakalaris.com/mob/privacy.aspx?language=en_us/"><li>Privacy Policy</li></a>
        <a href="http://kodakmoments.kodakalaris.com/mob/eula.aspx?language=en_us/"><li>Site Terms</li></a>
        <a href="https://kmotd.kodakmoments.com/app/contact/landing/kodak/"><li>Contact Us</li></a>
        <a href="http://apps.kodakmoments.com/kiosk/kiosk-locator/"><li>Find a kiosk</li></a>
      </ul>
    </div>
    <footer className="kmtg-holiday-footer-bottom">
        <div className="kmtg-holiday-footer-bottom-copy">
            <p>&copy; 2019 Kodak Alaris Inc.</p>
            <p>The Kodak and Kodak Moment trademarks and Kodak trade dress are used under license from Eastman Kodak Company.</p>
        </div>
        <SocialLinks />
    </footer>
    <ErrorBoundary>
        <IntlProvider {...intlProps}>
            <div className="holiday-combo-content">
                <Footer className="combo-footer" />
            </div>
        </IntlProvider>
    </ErrorBoundary>
    </>
  )
}

export default HolidayFooter;
