import React from 'react';
import FormattedMessage from '../utils/FormattedMessage'

class LASModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }


    render() {
        return (
            <div className="las-modal">
                <div className="las-modal-wrapper">
                    <div className="las-modal-title">
                        <FormattedMessage id={this.props.title} />
                    </div>
                    {this.props.message2 ? <div className="las-modal-body">
                        <div>
                            <FormattedMessage id={this.props.message} />
                        </div>
                        <div>
                            <FormattedMessage id={this.props.message2} />
                        </div>
                    </div>
                    :
                    <div className="las-modal-body">
                        <FormattedMessage id={this.props.message} />
                    </div>
                    }
                    <button onClick={() => {
                        this.props.showModal(false);
                    }}>
                        <FormattedMessage id={this.props.buttonId} />
                    </button>
                </div>
            </div>
        );
    }
}

export default LASModal;