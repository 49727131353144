import React from 'react';

const Header = (props) => {
    let graphicsLocale = "en";
    if (props.siteNameKey && props.siteNameKey === "site-name-kaspe") {
        graphicsLocale = "de";
    }

    if (props.toGoOverride) {
        graphicsLocale = "override";
    }

    return (
        <div className="header" lang={graphicsLocale} />
    );
}


export default Header;




