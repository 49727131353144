import React from 'react';
import './hero-banner.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function HeroBanner({heroImgSrc, hasPromo, urlSearchParams, isTest}) {
  const utm_source = urlSearchParams.get("utm_source");
  const utm_medium = urlSearchParams.get("utm_medium");
  const utm_campaign = urlSearchParams.get("utm_campaign");
  let lasUrl = '/'
  let source = "KM2GO_landing";
  let medium = "S2H";
  if (utm_source) {
    lasUrl = `/?utm_source=${utm_source}`;
    source = utm_source;
  }
  if (utm_medium) {
    lasUrl = lasUrl + `&utm_medium=${utm_medium}`;
    medium = utm_medium;
  }
  let kmUrl = `https://www.kodakmoments.com/?utm_source=${source}&utm_medium=${medium}`;
  if (utm_campaign) {
    lasUrl = lasUrl + `&utm_campaign=${utm_campaign}`;
    kmUrl = kmUrl + `&utm_campaign=${utm_campaign}`;
  }
  if (isTest) {
    lasUrl = lasUrl + "&findui=demo";
  }
  return (
    <Carousel infiniteLoop showThumbs={false} showArrows={false} showStatus={false} emulateTouch autoPlay>
      <section  style={{"background-size": "contain"}}className="hero hero-1 onboarding">
        <img alt="hero" src={heroImgSrc} />
        <div className="text-wrapper" style={{ marginLeft: "auto"}}>
          <h1><strong>Kodak Moments</strong> To Go</h1>
          <h2 className="hero-banner-head">Pick up prints at a store near you.</h2>
          <div>
              <p className="hero-banner-body">Order online for same-day</p>
              <p className="hero-banner-body">pickup at a nearby store.</p>
              {hasPromo ? <p>Use code: <strong className="code">MERRY25</strong></p> : null}
          </div>
          <a href={lasUrl} className="hero-banner-call-to-action">Order for Pickup</a>
        </div>
      </section>
      <section  style={{"background-size": "contain"}}className="hero hero-2 onboarding">
        <img alt="hero" src="/images/KMTG_Mobile_LandingPage-Hero-2_@2x.jpg" />
        <div className="text-wrapper" style={{ marginRight: "auto"}}>
          <h1><strong>PRINT THE POSSIBILITIES</strong></h1>
          <h2 className="hero-banner-head">Your moments, delivered.</h2>
          <div className="hero-banner-description">
              <p className="hero-banner-body">Turn photos into memorable gifts and</p>
              <p className="hero-banner-body">decor shipped straight to your door.</p>
          </div>
          <div className="hero-banner-mobile-description">
            <p className="hero-banner-body" style={{padding: "0 32px"}}>Turn photos into memorable gifts and decor shipped straight to your door.</p>
          </div>
          <a href={kmUrl} className="hero-banner-call-to-action">Create for Delivery</a>
        </div>
      </section>
      {/* <section  style={{"background-size": "contain"}}className="hero hero-3 onboarding"> */}
        {/* <img alt="hero" src="/images/KMTG_Mobile_LandingPage-Hero-3_@2x.jpg" /> */}
        {/* <div className="text-wrapper"> */}
          {/* <h2 className="hero-banner-head"><strong>For All That <br /> <span>Matters<span style={{fontSize: "14px", position: "relative", bottom: "22px"}}>TM</span></span></strong></h2> */}
          {/* <div className="hero-banner-description"> */}
              {/* <p className="hero-banner-body">Celebrate life's milestones and everyday moments</p> */}
              {/* <p className="hero-banner-body">again and again with <strong style={{letterSpacing: "unset"}}>Kodak Moments</strong>.</p> */}
          {/* </div> */}
          {/* <div className="hero-banner-mobile-description"> */}
            {/* <p className="hero-banner-body" style={{fontSize: "20px", padding: "0 20px"}}>Celebrate life's milestones and everyday moments again and again with <strong style={{letterSpacing: "unset"}}>Kodak Moments</strong>.</p> */}
          {/* </div> */}
        {/* </div> */}
      {/* </section> */}
    </Carousel>
    
  )
}

export default HeroBanner;
