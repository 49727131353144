//const analytics = require("./analytics");
//const perf = require("./performance");
const qs = require("query-string");
const urls = require("./urls.json");

let trackerUrlBase = urls.production.tracker;

const host = window.location.hostname;
if (host.includes("-stage")) {
    trackerUrlBase = urls.staging.tracker;
} else if (host.includes("-dev") || host === "localhost") {
    trackerUrlBase = urls.development.tracker;
}

export default {
    httpGetAsync(theUrl, callback) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === XMLHttpRequest.DONE) {
                if (xmlHttp.status === 200) {
                    callback(xmlHttp.responseText);
                } else {
                    callback({ error: `error: ${xmlHttp.status}` });
                }
            }
        };
        xmlHttp.open("GET", theUrl, true); // true for asynchronous 
        xmlHttp.send(null);
    },

    // Use the browser's built-in functionality to quickly and safely escape the string
    escapeHtml(str) {
        var div = document.createElement("div");
        div.appendChild(document.createTextNode(str));
        return div.innerHTML;
    },

    // locationFound will be called with a latitude and longitude if the address could be resolved into a coordinate
    // locationNotFound will be called in the case of an error or if the address could NOT be resolved into a coordinate
    findAddressLocation(searchAddress, locationFound, locationNotFound, geodata) {
        let country = "US";
        if (geodata && geodata.country) {
            country = geodata.country;
        }

        var address = this.escapeHtml(searchAddress);
        var url = trackerUrlBase + "geocode?address=" + address + "&country=" + country;
        this.httpGetAsync(url, (response) => {
            let resp = {};
            try {
                resp = JSON.parse(response);
            } catch (err) {
                console.error(response);
                locationNotFound("response is incorrect for geocode locator:" + err);
            }
            if (Array.isArray(resp)) {
                if (resp.length > 0) {
                    locationFound(resp[0].lat, resp[0].lng);
                } else {
                    locationNotFound("information returned was not an array" + JSON.stringify(resp));
                }
            } else {
                locationNotFound("error getting the location information:" + JSON.stringify(resp));
            }
        });
    },

    perfCleanup() {
        //perf.clearMarks("find-start");
        //perf.clearMarks("find-finish");
        //perf.clearMeasures("find-duration");
    },

    findVoyagersNearLocation(lat, long, radius, includeDemo, useDev, voyagersFound, voyagersNotFound) {
        // search for voyager systems withing 20 miles of the given lat/long coordinate
        var searchRadius = radius * 1609.344; // N miles @ 1609.344 meters per mile
        console.log(`Finding Voyagers near lat=${lat} long=${long} radius=${searchRadius} includeDemo=${includeDemo}`);
        var nonce = randomString(10);
        if (useDev) {
            trackerUrlBase = urls.development;
        }
        var url = trackerUrlBase + "find?latlng=" + lat + "," + long + "&radius=" + searchRadius + "&nonce=" + nonce;
        console.log(url);
        if (includeDemo) {
            url = url + "&demo";
        }
        this.perfCleanup();
        //perf.mark("find-start");
        this.httpGetAsync(url, (response) => {
            //perf.mark("find-finish");
            try {
                //perf.measure("find-duration", "find-start", "find-finish");
                //const entries = perf.getEntriesByName("find-duration", "measure");
                //console.log(`Search took ${entries[0].duration} ms`);
                //analytics.reportTimer(entries[0].duration);
                this.perfCleanup();
            } catch (error) {
                console.error(`Calculating and reporting search time: ${error}`);
            }
            let resp = {};
            try {
                resp = JSON.parse(response);
            } catch (err) {
                console.error("Error from find call:");
                console.error(response);
                voyagersNotFound("response for store finder is incorrect:" + JSON.stringify(err));
            }

            // assuming that if resp is now an array that we got some real results (even if the count is zero)
            if (Array.isArray(resp)) {
                console.log(`Found ${resp.length} Voyagers`);
                resp.forEach((entry) => {
                    entry.hub = `${trackerUrlBase}`;
                });

                //
                // This section of code is temporary for the athena demo. It looks for particular systems in the
                // search results and constructs "anthena" entries for them. Each athena entries is either inserted
                // alongside the original entry, or replaces the original entry (depending on the "onlyAthena" value).
                //
                const serverids = [
                    // { serverid: "FE458D4C-30BC-11B2-A85C-ACD28F0EFA30" }, // SWL Athena 1
                    { serverid: "B2B939CC-2050-11B2-A85C-A7B6E2B50BA7" }  // SWL Athena 2
                ]; 

                // only add Athena entries for available systems (that is, can find serverid in the search results, or search result's 
                // storeid contains the string "athena")
                const athenaSystems = resp.filter(item => {
                    const serverIdIndex = serverids.findIndex(p => item.serverid === p.serverid);
                    const storeIdIndex = item.storeid.toLowerCase().indexOf("athena");
                    const isAthena = (serverIdIndex >= 0) || (storeIdIndex >= 0);
                    return isAthena;
                });

                // determine which environment's athena spe to launch based on the hostname of the search page being used
                const host = window.location.hostname;
                let athenaAppHub = "https://athena-public.azurewebsites.net/?v-server=https://athena-system-server.azurewebsites.net";
                if (host.includes("-stage")) {
                    athenaAppHub = "https://athena-public-stage.azurewebsites.net/?v-server=https://athena-system-server-stage.azurewebsites.net";
                } else if (host.includes("-dev") || host.includes("localhost"))  { 
                    athenaAppHub = "https://athena-public-dev.azurewebsites.net/?v-server=https://athena-system-server-dev.azurewebsites.net";
                }

                athenaSystems.forEach((element) => {
                    const athena = Object.assign({}, element);
                    athena.systemType = "athena"; // added to identify an athena system (need to do something different when launched)
                    athena.serverid = "@" + athena.serverid; // '@' pre-pended to make a unique key
                    athena.hub = athenaAppHub;
                    delete athena.dnsname; // prevent "hybrid connect" attempt when launching athena site

                    let index = resp.findIndex(r => r.serverid === element.serverid);
                    resp[index] = athena;
                });                     
                
                voyagersFound(resp);
            } else {
                console.error("Error: find call's result were not an array:");
                console.error(resp);
                voyagersNotFound("Error: find call's result were not an array:" + JSON.stringify(resp));
            }
        });
    },
    
    insertAthenaEntries(resp, serverids)
    {
   
    },

    getQueryStringValue(key) {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split("=");
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars[key];
    },

    // The URL by which this page was called might have extra query parameters in it beyond those
    // we recognize, for tracking advertising campaigns. As you add new qs params which this page
    // understands, you should add code here to remove them. This function then returns a string
    // containing the passed-in URL plus only the "extra" parameters.
    addExtraQueryParams(target) {
      let qps = qs.parse(qs.extract(window.location.href));
      delete qps.dev;
      delete qps.findui;
      delete qps.nohybrid;
      delete qps.radius;
      delete qps.t;
      const str = qs.stringify(qps);
      if (str.length > 0) {
        const qmark = target.indexOf("?");
        if (qmark < 0) {
          target = `${target}?${str}`;
        } else if (qmark === target.length-1) {
          target = `${target}${str}`;
        } else {
          target = `${target}&${str}`;
        }
      }
      return target;
    }

};

function randomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
