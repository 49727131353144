const { injectIntl } = require("react-intl");
const React = require("react");

module.exports = injectIntl(props => {

  const text = { __html: props.intl.formatMessage({ id: 'google-privacy-policy' }) };

  return (
    <span dangerouslySetInnerHTML={text}></span>
  );
});