import React from 'react';
import './module-five.css';

function ModuleFive({urlSearchParams, isTest}) {
  const utm_source = urlSearchParams.get("utm_source");
  const utm_medium = urlSearchParams.get("utm_medium");
  const utm_campaign = urlSearchParams.get("utm_campaign");
  let lasUrl = '/'
  if (utm_source) {
    lasUrl = `/?utm_source=${utm_source}`;
  }
  if (utm_medium) {
    lasUrl = lasUrl + `&utm_medium=${utm_medium}`;
  }
  if (utm_campaign) {
    lasUrl = lasUrl + `&utm_campaign=${utm_campaign}`;
  }
  if (isTest) {
    lasUrl = lasUrl + "&findui=demo";
  }
  return (
    <>
      <section className="find">
          <div className="content">
              <h1>Create prints instantly at your nearest <strong>Kodak Moments</strong> To Go.<br/> <a style={{marginTop: "30px"}} href={lasUrl} className="call-to-action">Find a Store</a></h1>
              {/* <a href="/" className="call-to-action">Find a Store</a> */}
          </div>
      </section>
      <div className="mobile-find">
        <div className="img-wrapper">
          <img src="/images/KMTG_Mobile_LandingPage-Mod-5.jpg" alt="store-locator" />
        </div>
        <div className="content">
              <h1>Create prints instantly <br/>at your nearest <br/><strong>Kodak Moments</strong> To Go.</h1>
              <a href={lasUrl} className="call-to-action">Find a Store</a>
          </div>
      </div>
    </>
  )
}

export default ModuleFive;
