import Util from '../utils/utility.js';
const addLocaleData = require("react-intl").addLocaleData;

export default {

    IntlProviderProps() {

        let defaultLanguage = "en";

        // determine browser language
        let locale = defaultLanguage;
        if (navigator.language) {
            locale = navigator.language.split(/(-|_)/)[0];
        }
        let defaultLocale = locale;

        let localeData = {
            de: require("react-intl/locale-data/de"),
            en: require("react-intl/locale-data/en"),
            es: require("react-intl/locale-data/es"),
            fr: require("react-intl/locale-data/fr"),
            pl: require("react-intl/locale-data/pl")
        };

        let allMessages = {};
        allMessages["de"] = require("./de-DE.json");
        allMessages["en"] = require("./en-US.json");
        allMessages["es"] = require("./es-ES.json");
        allMessages["fr"] = require("./fr-FR.json");
        allMessages["pl"] = require("./pl-PL.json");

        let messages = allMessages[locale];

        /**
         * This section of code is an override for setting the language of the application.
         * If the user specifies a 'lang' parameter in the URL, it will get checked here.
         * By default (or by supplying an unknown language), the language will be set to
         * English. If the language supplied is 'de', 'es', or 'fr', the language will
         * be set accordingly.
         */
        let specifiedLanguage = Util.getQueryStringValue("lang");
        if (specifiedLanguage) {
            console.log('User has specified that the language be set to: ' + specifiedLanguage + '.');
            switch (specifiedLanguage) {
                case 'de':
                    locale = 'de';
                    break;
                default:
                    console.log('The language ' + specifiedLanguage + ' is not a correct language.');
                    console.log("Try 'de', 'en', 'es', 'pl' or 'fr'. Defaulting to english for now.");
                    locale = 'en';
                    break;
                case 'en':
                    locale = 'en';
                    break;
                case 'es':
                    locale = 'es';
                    break;
                case 'fr':
                    locale = 'fr';
                    break;
                case 'pl':
                    locale = 'pl';
                    break;
            }
            messages = allMessages[locale];
        }

        if (!messages) {
            locale = defaultLanguage;
            defaultLocale = defaultLanguage;
            messages = allMessages[defaultLanguage];
        }

        addLocaleData(localeData[locale]);

        return {
            locale,
            messages,
            defaultLocale,
            allMessages
        };
    }
};
