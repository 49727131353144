import React from 'react';
import FormattedMessage from '../utils/FormattedMessage'
const GooglePrivacyComponent = require("../utils/GooglePrivacyComponent.jsx");

const Footer = (props) => {
    let classname = "footer";
    if (props.hasOwnProperty("className")) {
        classname = props.className;
    }

    return (
        <div className={classname}>
            <p className="google-privacy-text"><GooglePrivacyComponent /></p>
            <p><FormattedMessage id="footer-copyright" /></p>
            <p><FormattedMessage id="footer-trademark" /></p>
        </div>
    );
}

export default Footer;