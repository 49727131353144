import React from 'react';
import './module-two.css';

const chevron = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-compact-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
    </svg>
)

function ModuleTwo({urlSearchParams, isTest}) {
  const utm_source = urlSearchParams.get("utm_source");
  const utm_medium = urlSearchParams.get("utm_medium");
  const utm_campaign = urlSearchParams.get("utm_campaign");
  let lasUrl = '/'
  let source = "KM2GO_landing";
  let medium = "S2H";
  if (utm_source) {
    lasUrl = `/?utm_source=${utm_source}`;
    source = utm_source;
  }
  if (utm_medium) {
    lasUrl = lasUrl + `&utm_medium=${utm_medium}`;
    medium = utm_medium;
  }
  let kmUrl = `https://www.kodakmoments.com/?utm_source=${source}&utm_medium=${medium}`;
  if (utm_campaign) {
    lasUrl = lasUrl + `&utm_campaign=${utm_campaign}`;
    kmUrl = kmUrl + `&utm_campaign=${utm_campaign}`;
  }
  if (isTest) {
    lasUrl = lasUrl + "&findui=demo";
  }
  return (
    <section className="module-two">
        <p className="module-two-headline"><span>3 WAYS TO PRINT</span></p>
        <p className="module-two-headline-mobile"><span>3 WAYS TO PRINT</span></p>
        <div className="content-wrapper">
            <div className="bucket step-1">
                <img srcSet="/images/KMTG_LandingPage-Mod-2-B_@2x.png 2x, /images/KMTG_LandingPage-Mod-2-B.png 1x"
                    src="/images/KMTG_LandingPage-Mod-2-B.png"
                    alt="LandingPage-Mod-2-B" />
                <p className="module-two-image-one-body"> Order prints online for same-day pickup</p>
                <a href={lasUrl}>Create Order {chevron}</a>
            </div>
            <div className="bucket step-2">
                <img srcSet="/images/KMTG_LandingPage-Mod-2-C_@2x.png 2x, /images/KMTG_LandingPage-Mod-2-C.png 1x"
                    src="/images/KMTG_LandingPage-Mod-2-C.png"
                    alt="LandingPage-Mod-2-C" />
                <p className="module-two-image-two-body">Create and print photos instantly in store</p>
                <a href={lasUrl}>Find A Store {chevron}</a>
            </div>
            <div className="bucket step-3">
                <img srcSet="/images/KMTG_LandingPage-Mod-2-A_@2x.png 2x, /images/KMTG_LandingPage-Mod-2-A.png 1x"
                    src="/images/KMTG_LandingPage-Mod-2-A.png"
                    alt="LandingPage-Mod-2-A" />
                <p className="module-two-image-three-body">Order online for delivery to your door</p>
                <a href={kmUrl}>Shop Now {chevron}</a>
            </div>
        </div>
    </section>
  )
}

export default ModuleTwo;
