import React from 'react';
import './module-three.css';
// line breaks are different on desktop and mobile for "module-three-body" text.
// That's why we have two similar looking p tags in our return() below.
// This was done to accomodate marketing requests for specific line breaks.
//
function ModuleThree({urlSearchParams}) {
  const utm_source = urlSearchParams.get("utm_source");
  const utm_medium = urlSearchParams.get("utm_medium");
  const utm_campaign = urlSearchParams.get("utm_campaign");
  let source = "KM2GO_landing";
  let medium = "S2H";
  if (utm_source) {
    source = utm_source;
  }
  if (utm_medium) {
    medium = utm_medium;
  }
  let kmUrl = `https://www.kodakmoments.com/?utm_source=${source}&utm_medium=${medium}`;
  if (utm_campaign) {
    kmUrl = kmUrl + `&utm_campaign=${utm_campaign}`;
  }
  
  return (
    <section className="one-stop-shop">
        <div className="img-wrapper">
            <img srcSet="/images/KMTG_LandingPage-Mod-3_@2x.jpg 2x, /images/KMTG_LandingPage-Mod-3.jpg 1x"
                src="/images/KMTG_LandingPage-Mod-3.jpg"
                alt="KMTG_LandingPage-Mod-3" />
        </div>
        <div className="content">
            <h3 className="module-three-head">DELIVERED TO YOU</h3>
            <h2 className="module-three-sub-head">Unique decor.</h2>
            <p className="module-three-body">Design an easy, memorable focal <br/>point with different Canvas sizes <br/>featuring moments you love. <br/>Elevate your photos for a stunning <br/>look with our best-selling Canvas.</p>
            <p className="module-three-body-mobile">Design an easy, memorable focal point with different Canvas sizes featuring moments you love. Elevate your photos for a stunning look with our best-selling Canvas.</p>
            <a href={kmUrl} className="module-three-call-to-action">Shop For Delivery</a>
        </div>
    </section>
  )
}

export default ModuleThree;
