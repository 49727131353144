import React from 'react';
import './module-four.css';

function ModuleFour({urlSearchParams, isTest}) {
  const utm_source = urlSearchParams.get("utm_source");
  const utm_medium = urlSearchParams.get("utm_medium");
  const utm_campaign = urlSearchParams.get("utm_campaign");
  let lasUrl = '/'
  if (utm_source) {
    lasUrl = `/?utm_source=${utm_source}`;
  }
  if (utm_medium) {
    lasUrl = lasUrl + `&utm_medium=${utm_medium}`;
  }
  if (utm_campaign) {
    lasUrl = lasUrl + `&utm_campaign=${utm_campaign}`;
  }
  if (isTest) {
    lasUrl = lasUrl + "&findui=demo";
  }
  return (
      <section className="easy-diy">
        <div className="img-wrapper">
            <img srcSet="/images/KMTG_LandingPage-Mod-4_@2x.jpg 2x, /images/KMTG_LandingPage-Mod-4_@2x.jpg 1x"
                src="/images/KMTG_LandingPage-Mod-4.jpg"
                alt="KMTG_LandingPage-Mod-4" />

        </div>
        <div className="content">
            <h3 className="module-four-head">SAME-DAY PICKUP</h3>
            <h2 className="module-four-sub-head">Print every moment.</h2>
            <p className="module-four-body">Make your photos last beyond your <br/>camera roll. From family vacations <br/>to playful pet portraits, print your <br/>favorite moments to share and save.</p>
            <a href={lasUrl} className="easy-diy-call-to-action">Order For Pickup</a>
        </div>
      </section>
  )
}

export default ModuleFour;
