import React from 'react';
import LASMachine from './LASMachine.jsx';
import LASSearch from './LASSearch.jsx';
import FormattedMessage from '../utils/FormattedMessage'
import LASModal from './LASModal.jsx';


class Body extends React.Component {
    constructor(props) {
        super(props);

        this.updateMachineList = this.updateMachineList.bind(this);
        this.setSearching = this.setSearching.bind(this);
        this.isSearching = this.isSearching.bind(this);
        this.setError = this.setError.bind(this);

        this.countriesUseMiles = ['USA', 'GBR', 'MMR', 'LBR', 'ASM', 'BHS', 'BLZ', 'VGB', 'CYM', 'DMA', 'FLK',
            'GRD', 'GUM', 'MNP', 'WSM', 'LCA', 'VCT', 'SHN', 'KNA', 'TCA', 'VIR']

        this.state = {
            machines: [],
            searching: false,
            errorId: null,
            country: null,
            showModal: false,
        };
    }

    setCountryState = (value) => {
        this.setState({country: value});
    }

    showModal = (value) => {
        this.setState({ showModal: value });
    }

    render() {
        // Machine list is created from the machines from the state
        const machineList = this.state.machines.map(machine => {
            return (
                <LASMachine key={machine.serverid}
                    machine={machine}
                    mode={this.props.mode}
                    debug={this.props.debug}
                    miles={this.countriesUseMiles.includes(machine.country)}
                    setLoading={() => this.props.setLoading()}
                    country={this.state.country}
                />
            );
        });

        // Error message is only created if there is an error and we don't
        // have any machines to display
        let noLocations = null;
        let modal = null;
        if (this.state.errorId && this.state.machines.length <= 0) {
            noLocations = (
                <p className="no-results">
                    <FormattedMessage id={this.state.errorId} />
                </p>
            );
            let title = null;
            let message = null;
            let message2 = null;
            let buttonId = null;
            if (this.state.errorId === "locator-status-unknown") {
                title = "no-stores-found";
                message = "confirm-location";
                buttonId = "ok";
            } else {
                title = "we're-sorry";
                message = "error-message";
                message2 = "error-message-2"
                buttonId = "ok";
            }
            if (this.state.showModal) {
                modal = <LASModal 
                    title={title}
                    message={message}
                    buttonId={buttonId}
                    message2={message2}
                    showModal={this.showModal}
                />
            }
        }

        return (
            <div className="combo-hero-image-container">
                <img alt="Framed photos" className="combo-hero-image" src="/images/store-locator-hero-holiday.jpg" />
                <div className="combo-hero-image-text" id="welcome">
                    <p className="para">
                        <FormattedMessage id="locator-explain" />
                    </p>
                    <p className="cta">
                        <FormattedMessage id="locator-to-begin" />
                    </p>
                    {this.createSearch()}
                    <hr className="double-rule" />
                    {machineList}
                    {/* {noLocations} */}
                    {modal}
                </div>
            </div>
        );
    }

    /**
     * Creates the LASSearch HTML tag.
     * 
     * Moved out of the render method to keep it cleaner, as the LASSearch tag takes
     * a ton of props.
     */
    createSearch() {
        return (
            <LASSearch messages={this.props.messages}
                updateMachineList={this.updateMachineList}
                setSearching={this.setSearching}
                setError={this.setError}
                mode={this.props.mode}
                debug={this.props.debug}
                searchRadius={this.props.searchRadius}
                useDev={this.props.useDev}
                isSearching={this.isSearching}
                setCountryState={this.setCountryState}
                showModal={this.showModal}
                appInsights={this.props.appInsights}
            />
        );
    }

    /**
     * If there is an error to be displayed, this function is used to do that.
     * 
     * setError is used to set error messages beneath the double-rule on the search page.
     * This function is currently only used to either clear the error message or tell
     * the user that were no voyagers found.
     * 
     * @param   id      The ID of the localized string to display on the screen.
     */
    setError(id = null) {
        this.setState(
            {
                errorId: id
            }
        );
    }

    /**
     * Updates the machines in the state to the passed in argument.
     * 
     * By default, calling this method with no parameters will clear the currently listed
     * machines. By updating the state, this will cause a re-render and clear out the
     * displayed machines. If a machines list is passed in, the machines list in the state
     * will be updated (causing a re-render) and the new list will be displayed on screen.
     * 
     * @param   machs   List of machines to display on-screen (default is an empty list)
     */
    updateMachineList(machs = []) {
        this.setState({
            machines: machs,
        });
    }

    /**
     * Sets the searching value in state to 'value'.
     * 
     * This functions sets the 'searching' boolean in the state to true or false.
     * This is used for detecting if we should display a spinner on the screen,
     * indicating that something is going on behind the scenes that the user should
     * wait for.
     * 
     * @param   value   True or False, depending on what value you want searching to be set to.
     */
    setSearching(value) {
        this.setState({
            searching: value,
        });
    }

    /**
     * Returns if we are currently searching.
     */
    isSearching() {
        return this.state.searching;
    }
}

export default Body;