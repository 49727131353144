import React from 'react';
// HeroBanner, Numbered Modules 1-5,
// updated with new content for VGR-3240
// https://docs.google.com/spreadsheets/d/1Uhsd5rODYBdYv1-p7O4xCvmSapv7ynbGXtKWXrG25bY/edit#gid=0
// reach out to Claire Banks-Byars for file permissions.

import HeroBanner from './HeroBanner/HeroBanner.jsx';
import ModuleOne from './ModuleOne/ModuleOne.jsx';
import ModuleTwo from './ModuleTwo/ModuleTwo.jsx';
import ModuleThree from './ModuleThree/ModuleThree.jsx';
import ModuleFour from './ModuleFour/ModuleFour.jsx';
import ModuleFive from './ModuleFive/ModuleFive.jsx';
import HolidayFooter from './HolidayFooter/HolidayFooter.jsx';
import '../Onboarding.css';

function HolidayLandingPage({hasPromo, heroImgSrc}) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const host = window.location.hostname;
  let isTest = false;
  if (host.includes("-dev") || host === "localhost" || host.includes("-stage")) {
    isTest = true;
  }
  return (
    <>
    <HeroBanner
      heroImgSrc={heroImgSrc}
      hasPromo={hasPromo}
      urlSearchParams={urlSearchParams}
      isTest={isTest}
    />
    <ModuleOne urlSearchParams={urlSearchParams} isTest={isTest}/>
    <ModuleTwo urlSearchParams={urlSearchParams} isTest={isTest}/>
    <ModuleThree urlSearchParams={urlSearchParams} isTest={isTest}/>
    <ModuleFour urlSearchParams={urlSearchParams} isTest={isTest}/>
    <ModuleFive urlSearchParams={urlSearchParams} isTest={isTest}/>
    <HolidayFooter urlSearchParams={urlSearchParams} isTest={isTest}/>
    </>
  )
}

export default HolidayLandingPage;
