import React from 'react';
import Header from "../landing/LASHeader.jsx";

import './TrackerHelper.css';

const TrackerHelperWrapper = (props) => {
    let heroImgSrc = "/images/KMTG_Mobile_LandingPage-Hero.jpg";

    return (
        <div className="landing-wrapper">
            <Header />
            <section className="hero">
                <img alt="hero" src={heroImgSrc} />
                <div className="text-wrapper">
                    <h1><strong>Kodak Moments</strong> To Go</h1>
                    <h2>System out of service</h2>
                    <div>
                        <p>The system you have tried to access is currently out of service. Please try again later. </p>
                    </div>
                    <a href="/" className="call-to-action">Back to search</a>
                </div>
            </section>
            <footer>
                <p>&copy; 2019 Kodak Alaris Inc.</p>
                <p>The Kodak and Kodak Moment trademarks and Kodak trade dress are used under license from Eastman Kodak Company.</p>
            </footer>
        </div>
    )
}

export default TrackerHelperWrapper;