import React from 'react';
import ReactDOM from 'react-dom';
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import LASLandingWrapper from './landing/LASLanding.jsx';
import Onboarding from './onboarding/Onboarding.jsx';
import TrackerHelper from './tracker-helper/TrackerHelper.jsx';
import * as serviceWorker from './serviceWorker';
import Util from './utils/utility.js';
let appInsights = null;
let insightsKey = "7f264d2b-ffb2-45d9-8131-6328d2b2d7ef";

const host = window.location.hostname;
if (host.includes("-stage")) {
    insightsKey = "b8d60ea3-c956-473a-98bd-9758d948d697";
} else if (host.includes("-dev") || host === "localhost") {
    insightsKey = "d65635ed-e691-4304-9e0e-fd252b464c40";
}

const insightsInit = () => {
    console.log(insightsKey)
    appInsights = new ApplicationInsights({ 
        src: "https://js.monitor.azure.com/scripts/b/ai.2.min.js",
        config: {
            instrumentationKey: insightsKey,
            maxAjaxCallsPerView: -1,     // setting to infinite unless we have issues with data overload
            enableAutoRouteTracking: true,   // tracks a page view for SPA apps
            disableFetchTracking: false,
            // enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
    const telemetryInitializer = (envelope) => {
        try{
            envelope.baseData.properties = envelope.baseData.properties || {};
        }
        catch(ex) {}
    };
    appInsights.addTelemetryInitializer(telemetryInitializer);
}

// Do a bit of startup logic to check which URL we're loading against
// If we load this page through kodakprintplace.com, just go to go.kodakmoments.com
// Fix for VGR-390 - Using kodakprintplace.com URL does not auto redirect user
if (window.location.href.indexOf("kodakprintplace.com") >= 0) {
    window.location.replace("https://go.kodakmoments.com");
}

// If we load this page through kodakmomentsexpress.com, just go to go.kodakmoments.com
// Fix for VGR-1167 - Using kodakmomentsexpress.com URL does not auto redirect user
if (window.location.href.indexOf("kodakmomentsexpress.com") >= 0) {
    window.location.replace("https://go.kodakmoments.com?t=hermes");
}

try {
    if ((Util.getQueryStringValue("web-demo-mode") !== "true") && 
        (Util.getQueryStringValue("console-logging") !== "true")) {
        console.log("Disabling console logging for site");
        console.log = () => { }
        console.warn = () => { }
        // console.error = () => { }
    }
} catch (err) {

}


if (window.BROWSER_IS_SUPPORTED) {
    let dev = (Util.getQueryStringValue("dev") === 'true');
    let mode = Util.getQueryStringValue("findui");
    let debugFlag = Util.getQueryStringValue('debug');
    let debug = false;
    if (debugFlag && debugFlag === 'true') {
        debug = true;
    }

    let typeValue = Util.getQueryStringValue("t");
    if (typeValue === 'hermes') {
        document.title = 'KODAK MOMENTS To Go';
        console.log('The website title has been set according to the filter type for hermes.');
    } else {
        console.log('No filter type query parameter found.');
    }


    let radiusValue = Util.getQueryStringValue("radius");
    if (radiusValue && !isNaN(radiusValue)) {
        if (radiusValue <= 0) {
            console.log('The user specified a 0 or negative search radius.');
            console.log('Default search radius will be set to 20.');
            radiusValue = 20;
        } else {
            console.log('The radius value (search radius of machines) has been set the user.');
            console.log("The search radius has been set to the value '" + radiusValue + "'.");
        }
    } else {
        console.log('The user did not specify a (correct) radius value in the query parameters.');
        console.log('Default search radius will be set to 20.');
        radiusValue = 20;
    }

    if (window.location.href.indexOf("/landing") >= 0) {
        let promo = false;
        if (window.location.href.indexOf("promo") >= 0) {
            promo = true;
        }
        ReactDOM.render(<Onboarding hasPromo={promo} />,
            document.getElementById('root')
        );
    } else if (window.location.href.indexOf("/tracker") >= 0) {
        ReactDOM.render(<TrackerHelper />,
            document.getElementById('root')
        );
    } else {
        insightsInit();
        console.log("application insights enabled");
        ReactDOM.render(<LASLandingWrapper searchRadius={radiusValue}
            filterType={typeValue}
            mode={mode}
            useDev={dev}
            debug={debug}
            appInsights={appInsights}
        />,
            document.getElementById('root'));

        try {
            serviceWorker.unregister();
        } catch (err) {
            console.log("Unable to unregister serviceworker. Err: " + err.message);
        }
    }


} else {
    console.log("Browser is not supported");
}


